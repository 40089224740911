<script setup>
defineOptions({
    inheritAttrs: false,
});

const props = defineProps({
    label: String,
    labelClass: String,
    disclaimer: String,
    inputClass: String,
    modelValue: String,
    magnifierRight: { type: Boolean, default: false },
    error: [String, Boolean],
});

const attrs = useAttrs();
const input = ref(null);
const emit = defineEmits(['onAutocomplete:input', 'blur', 'update:modelValue']);

const text = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value),
});

function emitAutoComplete(event, hasText) {
    emit('onAutocomplete:input', event, hasText);
}

onMounted(() => {
    input.value.addEventListener('keydown', () => {
        if (!text.value) {
            emitAutoComplete(text.value, false);
        }
        emitAutoComplete(text.value, true);
    });
    input.value.addEventListener('keypress', (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    });
    input.value.addEventListener('input', () => {
        if (!text.value) {
            emitAutoComplete(text.value, false);
        }
    });

    input.value.addEventListener('keyup', (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    });

    input.value.addEventListener('blur', () => {
        emit('blur', text.value);
    });
});
</script>

<template>
    <div
        class="form-control-wrapper"
        :class="[{ 'has-error': error }, attrs.class]"
    >
        <slot name="label">
            <label
                v-if="label"
                :class="['sr-only', labelClass]"
                :for="attrs.id ?? attrs.name"
                v-text="label"
            />
        </slot>
        <div class="relative">
            <input
                ref="input"
                v-model="text"
                :class="
                    magnifierRight
                        ? ['pr-[44px] form-control w-full border-r', inputClass]
                        : ['pl-[44px] form-control w-full', inputClass]
                "
                type="search"
                :placeholder="label"
                v-bind="attrs"
                @onAutocomplete:input="emitAutoComplete"
            />
            <IconMagnifier
                class="w-4 stroke-current text-slate-400 absolute top-1/2 -translate-y-1/2"
                :class="magnifierRight ? 'right-4' : 'left-4'"
            />
        </div>
        <InputErrorMessage
            :error="error"
            :field-label="label"
            :field-name="attrs.name"
        />
    </div>
</template>
